<template>
  <AdminView>
    <h1 class="title-1">Établissements</h1>

    <EstablishmentForm
      v-for="(establishment, i) in establishments" :key="i"
      :establishment="establishment"
    />

    <div class="buttons-container">
      <Button
        title="Ajouter un établissement"
        type="primary"
        @click="addEstablishment()"
      >
        <SVGPlus />
      </Button>
    </div>
  </AdminView>
</template>

<script>
import AdminView from '@/components/AdminView'
import EstablishmentForm from '@/components/Forms/EstablishmentForm'
import Button from '@/components/Button'
import SVGPlus from '@/assets/icons/plus.svg'

export default {
  name: 'EstablishmentsList',
  components: {
    AdminView, EstablishmentForm, Button, SVGPlus
  },
  data () {
    return {
      establishments: [
        {
          name: 'USINE 1 France',
          type: 1,
          country: 'France',
          states: ''
        },
        {
          name: 'A1 Hauts de France',
          type: 2,
          country: 'France',
          states: '59, 62'
        },
        {
          name: 'A2 Hauts de France',
          type: 2,
          country: 'France',
          states: ''
        }
      ]
    }
  },
  methods: {
    addEstablishment () {
      this.establishments.push({})
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container{
  display: flex;
  justify-content: center;
  margin: 20px 0;

  div {
    flex: 0;
  }
}
</style>
