<template>
  <div class="column establishment">
    <div class="row fields">
      <Input
        type="text"
        title="Nom de l'établissement"
        v-model="establishment.name"
      />

      <Select
        title="Type"
        :options="['Usine', 'Agence']"
        v-model="establishment.type"
      />

      <Input
        type="text"
        title="Pays"
        v-model="establishment.country"
      />

      <Input
        type="text"
        title="Départements / Provinces"
        v-model="establishment.states"
      />
    </div>

    <div class="row buttons-container">
      <Button
        title="Modifier"
        type="secondary"
      />
      <Button
        title="Supprimer"
        type="warning"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/Forms/Fields/Input'
import Select from '@/components/Forms/Fields/Select'
import Button from '@/components/Button'

export default {
  name: 'EstablishmentForm',
  components: {
    Input, Select, Button
  },
  props: ['establishment']
}
</script>

<style lang="scss" scoped>
.establishment{
  border-bottom: 1px solid $light-grey-color;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.fields{
  & > div{
    flex-grow: 1;
  }
}

.buttons-container{
  justify-content: flex-end;
  padding: 0 10px;
}
</style>
